//@import url('https://fonts.googleapis.com/css?family=Ropa+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo+2&display=swap');
@font-face {
  font-family: 'dinregular';
  src: url('../fonts/font_1-webfont.woff2') format('woff2'),
  url('../fonts/font_1-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
$dinRegular: 'dinregular';
body{
  //font-family: 'Ropa Sans', sans-serif !important;
  //font-family: $dinRegular, sans-serif !important;
  font-family: 'Exo 2', sans-serif!important;
  background-color: #f0f5f9;
}

h1 {
  font-size: 30px;
  margin: 0px;
}
h2 {
  font-size: 20px;
}
h3 {
  font-size: 15px;
}
.listing-page-filter .filter-top{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px 10px;
}
.filter-top .btn-group-right{
  margin-left: auto;
}
.listing-page-filter .filter-bottom{
  padding: 15px 0px 5px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.filter-bottom-left, .filter-bottom-right {
  display: flex;
  line-height: 20px;
}
.filter-bottom-left .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.filter-bottom-left  .ant-form-item-control-input-content .ant-btn{
  border-radius: 0;
}
.filter-box-left.input-box.search-box .ant-row.ant-form-item {
  margin: 0;
  padding: 0;
  width: 200px;
}
.listing-page-filter .filter-bottom-left .ant-input-search-icon:before{
  border-left: none;
}
.filter-box-left.select-box {
  padding-left: 10px;
}
.filter-box-left.select-box {
  padding-right: 10px;
  padding-left: 0;
}
.ant-form-item-control-input-content{
  border: 1px solid #d9d9d9;
}
.ant-form-item-control-input-content .ant-btn{
  padding-right: 0;
}
.filter-bottom-left .ant-form-item-control-input-content{
  display: flex;
  margin-bottom: 10px;
}
.filter-box-left.input-box.search-box{
  padding-right: 10px;
}

.filter-bottom-right .filter-box-right.button-box.reload-button-box{
  padding-bottom: 10px;
}

.filter-bottom-left{
  flex-wrap: wrap;
}
.filter-bottom .ant-btn {
  border: none !important;
}
.filter-bottom .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
  border: none !important;
}
.filter-bottom  .ant-btn-link{
  background-color: #ffffff !important;
  margin-left: 12px;
  color:  #000;
}
.filter-bottom  .bulk-action-button-box{
  margin-left: 10px;
}

.listing-page-pagination{
  margin-top: 15px;
  padding-left: 3px;
  //border-top: 1px solid #d1d1d1;
  //padding-top: 40px;
  //border-bottom: 1px solid #d1d1d1;
  //padding-bottom: 50px;
}
.pagination-container{
  text-align: right;
}
.ant-form-item{
  margin: 0px !important;
}
.navigation-search input {
  border-left: none;
  border-top: none;
  border-radius: 0;
}
.ant-form-item-label{
  text-align: left;
}
.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-item, .ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-vertical .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title {
  line-height: 34px;
}
.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-item, .ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-vertical .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title {
  margin-top: 0px;
}
.view-page-content-warehouse .ant-col.ant-col-24 {

}
.btn-edit{
  background: #F5F5F5;
  border-radius: 3px;
  border: 1px solid #EAEAEA;
  box-shadow: none;
  color: #000000;
  padding: 0px 15px;
  font-size: 13px;
  font-weight: 400;
  float: left;
  height: 26px;
}
.btn-delete{
  background: #F5F5F5;
  border-radius: 3px;
  border: 1px solid #EAEAEA;
  box-shadow: none;
  color: #000000;
  padding: 0px 15px;
  font-size: 13px;
  font-weight: 400;
  float: left;
  margin-left: 15px;
  height: 26px;
}
.ant-drawer-footer .ant-btn{
  margin-right: 15px;
  border-radius: 3px;
  box-shadow: 1px 1.73px 2px 0 rgba(170,170,170,.35);
  background: #fafafa;
}
.ant-btn:focus, .ant-btn:hover {
  color: #40a9ff;
  background: #fff;
  border-color: #EA4A69;
}
.ant-dropdown .ant-btn:focus, .ant-dropdown .ant-btn:hover {
  color: #ea4a69;
  background: transparent;
  border-color: transparent;
}
.ant-dropdown.avatar-menu-drop-down.ant-dropdown-placement-bottomRight{
  background: #fff;
  padding: 5px 15px;
  box-shadow: 0 5px 10px #0b132b26;
}
.ant-dropdown.ant-dropdown-placement-bottomRight{
  box-shadow: 0 5px 10px #0b132b26;
  background: #fff;
  padding: 5px 0px;
}
.ant-dropdown.ant-dropdown-placement-bottomRight .ant-dropdown-menu{
  box-shadow: none;
}
.ant-dropdown.ant-dropdown-placement-bottomRight .grid-actions-list{
  display: flex;
  flex-direction: column;
}
.ant-dropdown.avatar-menu-drop-down.ant-dropdown-placement-bottomRight .profile-menu-list .profile-menu-item {
  padding-bottom: 10px;
}
.ant-btn.ant-dropdown-trigger.ant-dropdown-open.table-row-action{
  border-color: #EA4A69;
}
.ant-dropdown.avatar-menu-drop-down.ant-dropdown-placement-bottomRight .profile-menu-list .profile-menu-item a{
  color: #353535;
}
.ant-dropdown.avatar-menu-drop-down.ant-dropdown-placement-bottomRight .profile-menu-list .profile-menu-item a:hover{
  color: #ef4f6e;
}
.ant-drawer-footer .ant-btn-primary, .ant-modal-confirm-btns .ant-btn-primary, .ant-modal-content .ant-modal-footer .ant-btn-primary{
  background: linear-gradient(to bottom,#F95978 0,#EA4A69 100%);
}
.ant-btn-primary:focus, .ant-btn-primary:hover, .record-detail-info .actions button:hover{
  background: linear-gradient(to bottom,#EA4A69 0,#dc3858 100%);
  color: #fff;
}
.ant-modal-confirm-body svg{
  color: #EA4A69;
}
.ant-modal-body{
  background:#f5f5f5;
}
.ant-descriptions-bordered .ant-descriptions-item-content, .ant-descriptions-bordered .ant-descriptions-item-label {
  padding: 10px 24px;
}
.ant-form-item-has-success .ant-form-item-control-input .ant-select-show-search {
  width: 100% !important;
}
.ant-switch-handle:before {
  background: linear-gradient(to bottom,#f8f8f8 0,#f0f0f0 100%);
  border-color: #CCC;
}
.ant-switch-checked {
  background: linear-gradient(to bottom,#F95978 0,#EA4A69 100%);
}

.workspace-image{
  position: relative;
}

.workspace-image h1{
  padding-left: 15px;
  padding-top: 10px;
}
.workspace-image h3{
  padding-left: 15px;
}
.workspace-image img,
.mcem-dashboard img{
  position: absolute;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: 3;
}
.ant-drawer-footer{
  background-color: #F0F2F5;
  text-align: right;
  padding: 20px;
}


.record-detail-info .dealer_info_main_area{
  //background-color: red;
  display: flex;
  padding: 20px 10px;
}
.record-detail-info .actions{
  float: right;
  display: inline-flex;
  margin-right: 10px;
}
.record-detail-info .info{
  display: inline-block;
  margin-left: 40px;
}
.record-detail-info .more-sub-menu-item, .anticon {
  padding-left: 0px;
}
.record-detail-info .record-detail-button-bar{
  display: flex;
  padding: 0px 0 10px 125px;
}
.record-detail-info .btn-edit {

}
.record-detail-info .actions button{
  background: linear-gradient(to bottom,#F95978 0,#EA4A69 100%);
  color: #ffffff;
  border-radius: 3px;
  border: none;
  margin-left: 5px;
}
.record-detail-tabs .ant-menu {
  background-color: transparent;
}
.record-detail-tabs li.active {
  background-color: #ececec !important;
  border: 1px solid #d1d1d1;
  border-bottom: none!important;
}
.record-detail-tabs .ant-menu-item a {
  color: #000000 !important;
  font-size: 14px !important;
}
.record-detail-tabs .ant-menu-horizontal {
  line-height: 30px;
  border-color: #d1d1d1;
}
.record-detail-tabs .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu {
  padding: 0 20px;
  width: auto !important;
  border-bottom: none;
  border-radius: 3px 3px  0  0 ;
}
.record-detail-tabs-content{
  margin-top: 15px;
}
.record-detail-tabs-content .ant-menu-horizontal {
  padding: 0 15px;
}
.record-detail-info .info h2{
  margin-bottom: 0;
  font-weight: bold;
}
.record-detail-info .info p{
  margin-bottom: 0;
}
.record-detail-info .ant-avatar {
  vertical-align: text-bottom;
}
.record-detail-info .dealer_info_main_area{
  padding: 0 10px 20px 10px;
}

.record-detail-info .photo {
  display: inline-block;
  background: #fff;
  border-radius: 100px;
  border: 2px solid #eee;
  box-shadow: 5px 10px 9px #0b132b1f;
  margin-bottom: 20px;
}
.dealer-info-tabs-content .ant-collapse{
  border: none;
}
.dealer-info-tabs-content .ant-collapse-item{
  margin-bottom: 16px;
  border-bottom: 1px solid rgba(0,0,0,.1);
}
.dealer-info-tabs-content .ant-collapse-content{
  border-top: none;
}
.dealer-info-tabs-content .ant-collapse .ant-collapse-item .ant-collapse-header{
  background-color: #fbfafa;
  padding: 8px 16px 8px 40px;
  font-size: 16px;
  font-weight: bold;
}
.dealer-info-tabs-content .ant-descriptions-bordered .ant-descriptions-item-label{
  background-color: #f8faff;
  font-weight: bold;
}
.dealer-credit-limit-tabs-content .listing-content .ant-table-content .ant-table-tbody tr td{
  text-align: center;
}
.record-list-header .float-left{
  float: left;
}
.record-list-header .float-left button{
  border-radius: 3px;
  color: #333;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  padding: 6px 20px;
  background: #f8f8f8;
  background: -moz-linear-gradient(top,#f8f8f8 0,#f0f0f0 100%);
  background: -webkit-linear-gradient(top,#f8f8f8 0,#f0f0f0 100%);
  background: linear-gradient(to bottom,#f8f8f8 0,#f0f0f0 100%);
  border: 1px solid #AAA;
  box-shadow: 1px 1.73px 2px 0 rgba(170,170,170,.35) !important;
  margin-bottom: 15px;
}
.record-list-header .float-right{
  float: right;
}
.record-list-header{
  height: 50px;
}
.ant-dropdown.quick-link-drop-down.ant-dropdown-placement-bottomRight{
  background: #fff;
}

.sales-order-form-delivery-schedule{
  .delivery-schedule-action{
    text-align: right;
    .ant-btn-primary{
      margin-bottom: 15px;
  }
  }
}

.ant-spin-dot-item{
  background-color: #f0506f;
}

.form-page-modal-salesOrderItem #salesOrderItemForm h2{
  margin:0;
}


//step start

.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:after {
   background-color: #cacaca;
}
.ant-steps-item-content, .ant-steps-item-icon {
  vertical-align: top;
}
.ant-steps-item-title {
  padding-right: 5px;
  font-size: 14px;
  line-height: 24px;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 5px;
}
.ant-steps-item-title:after {
  top: 12px;
}


//step end


.dashboard-title-small{
  font-size: 36px;
}

.dashboard-title-large{
  font-size: 72px;
  line-height: 72px;
  position: relative;
  z-index: 4;
}

.Initiated, .Commented, .RECOMMENDED, .Reviewed, .Recommended{
  text-align: center;
  color: #74b9ff;
  border-radius: 3px;
  border: 1px solid #74b9ff;
  margin: 0 auto;
  max-width: 100px;
}
.Consented,.Requested,.Coordinated, .Cancelled{
  text-align: center;
  max-width: 100px;
  color: #ff7675;
  border-radius: 3px;
  border: 1px solid #ff7675;
  margin: 0 auto;
}
.Approved{
  text-align: center;
  color: #55efc4;
  border-radius: 3px;
  border: 1px solid #55efc4;
  margin: 0 auto;
  max-width: 100px;
}
.ant-table-content .ant-btn-link {
  color: #f75776;
  padding: 0;
  text-align: center;
  margin: 0 auto;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  border: none;
}
.ant-table-content .ant-btn-link:hover{
  color: #bfbfbf;
  padding: 0;
  text-align: center;
  margin: 0 auto;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background: #f0f2f5;
  border: none;
}
.workflow-step{
  padding-bottom: 20px;
}

//step start
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: #a9aebd;
}
.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
  color: #27ae60;
}
.ant-steps-item-process .ant-steps-item-icon {
  background: #565c6f;
  border: 1px solid #565c6f;
}
 .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:after {
   background-color: #a7adbf;
 }
.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:after {
  background-color: #a7adbf;
}

//step end


.ant-checkbox-indeterminate .ant-checkbox-inner:after{
  background: linear-gradient(to bottom, #F95978 0, #EA4A69 100%);
}
//.ant-checkbox-checked:after {
//  background: linear-gradient(to bottom, #F95978 0, #EA4A69 100%);
//}
.ant-checkbox-checked .ant-checkbox-inner {
  background: linear-gradient(to bottom, #F95978 0, #EA4A69 100%);
  border-color: #EA4A69;
}

.ant-menu-submenu>.ant-menu{
  padding: 0 20px;
}

.ant-menu-submenu>.ant-menu li a{
  color: #000;
}
//step start
//.ant-steps.ant-steps-horizontal.ant-steps-label-horizontal{
//  display: flex;
//}
.workflow-step{
  display: contents;
  max-width: 100%;
}
.ant-steps-small .ant-steps-item-title{
  padding-right: 5px;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 5px;
}
.ant-steps.ant-steps-horizontal.ant-steps-label-horizontal{
  display: contents;
}
.ant-steps.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item{
  flex-basis: auto;
}
.ant-steps.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item:last-child{
  padding-left: 0;
}
.ant-steps-item-icon {
  width: 24px;
  height: 24px;
  margin: 0 8px 0 0;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 24px;
}

//step end

.record-detail-tabs-content .btn-wrapper{
  display: flex;
  align-items: baseline;
}
.record-detail-tabs-content .btn-wrapper .btn-account-existing{
  margin-left: 20px;
  color: #232121;
  font-weight: bold;
}

.btn-create.ant-btn-primary{
  background: #EA4A69;
  margin: 10px 0;
}

.view-page-content-sales-order .ant-table-tbody>tr>td, .ant-table-thead>tr>th, .ant-table tfoot>tr>td, .ant-table tfoot>tr>th{
  padding: 5px;
}
.listing-page-filter button.ant-btn.tab-links-item.ant-btn-link.active {
  background: #fafafa;
  border-bottom: 0;
  margin-left: 0;
  padding: 0 20px;
  box-shadow: 1px 1px 10px #0000000d;
  border-radius: 5px 5px 0 0;
  min-width: 80px;
}
.listing-page-filter .tab-links .ant-btn-link{
  color: #4e4b4bd9;
  border: none;
}
.listing-page-filter .tab-links .ant-btn:focus, .listing-page-filter .tab-links .ant-btn:hover{
  color: #232222;
  background: transparent;
  font-weight: bold;
}
.view-modal-scrollbar .profile-info{
  margin-top: 15px;
}
.view-modal-scrollbar .profile-info h2{
  margin: 0;
}



.record-detail-info{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  max-width: 100%;
}
.record-detail-info .photo{
  width: 84px;
  height: 84px;
  max-width: 100%;
}
.record-detail-info .actions{
  flex-basis: 100%;
  max-width: 100%;
  justify-content: flex-end;
}
.record-detail-info .photo{
  //flex-basis: 10%;
  max-width: 100%;
}
.record-detail-info .info{
  flex-basis: 60%;
  max-width: 100%;
}
.record-detail-info .record-detail-button-bar{
  flex-basis: 100%;
  max-width: 100%;
  padding: 0px 0 5px 110px;
}
.record-detail-info .workflow-step{
  max-width: 100%;
}

.view-page-content-credit-limit .view-page-content-right{
  height: 100%;
}


.listing-page-container-report .back-to-list{
  margin-bottom: 10px;
}

.listing-page-container-report .back-to-list a{
  color: #5d90fd;
  padding-bottom: 13px;
}

.listing-page-container-report .listing-page-filter .filter-top {
  align-items: start;
  padding: 5px 0px 0px;
  flex-direction: column;
}

.listing-page-container-report .page-heading-title h1{
  font-size: 20px;
}
.listing-page-container-report .page-heading-title span{
  font-size: 14px;
}
.index-page-content-setting .setting-image{
  display: flex;
  align-items: center;
  height: 100vh;
  text-align: center;
  transform: translate(30%, 0px);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.filter-bottom-left .filter-box-left.button-box.reset-button-box button.ant-btn.ant-btn-link{
  color: #000000;
}
.listing-page-filter .filter-bottom-left .date-range-filter-box,
#openingListingFilterForm .date-range-filter-box,
#openingCollectionListingFilterForm .date-range-filter-box,
#openingSaleListingFilterForm .date-range-filter-box,
#challanReportListingFilterForm .date-range-filter-box,
#collectionTargetReportListingFilterForm .date-range-filter-box,
#speciallyProcessedDoReportListingFilterForm .date-range-filter-box,
#securityReportListingFilterForm .date-range-filter-box,
#salesTargetReportListingFilterForm .date-range-filter-box,
#salesQuantityReportListingFilterForm .date-range-filter-box,
#productWiseSalesReportListingFilterForm .date-range-filter-box,
#monetaryReportListingFilterForm .date-range-filter-box,
#dealerWiseSalesReportListingFilterForm .date-range-filter-box,
#dealerAndRetailerWiseSalesReportListingFilterForm .date-range-filter-box{
  display: flex;
}

.challan-info-tabs-content .ant-descriptions-item-label,
.challan-info-tabs-content .ant-descriptions-item-content{
  width: 50%;
}
.collection-info-tabs-content .ant-descriptions-item-content .ant-image{
  max-width: 60px;
  height: auto!important;
}
.collection-info-tabs-content .ant-descriptions-item-content .ant-image .ant-image-img{
  max-width: 100%;
}
.listing-table-footer{
  font-weight: bold;
  font-size: 16px;
}
.listing-table-footer .listing-total{
  text-align: right;
  padding-right: 50px;

}
.login-footer{
  bottom: 2%;
  position: absolute;
  right: 45%;
}
.ant-select-arrow .anticon.anticon-spin>svg {
  color: #F95978;
}

.sort-by-box {
  width: 200px;
}
.sort-by-box .sort-by-dropdown-list {
  position: relative;
  display: inline-block;
  width: 130px;
  top: -1px;
}


/* START: Rabbi */

/* END: Rabbi */