.activity-timeline-content .event {
  display: block;
  font-weight: bold;
}
.activity-timeline-content .author {
  display: block;
}
.activity-timeline-content .date-time {
  display: block;
}
.activity-timeline-content .comment {
  display: block;
  padding: 10px 0;
}
.activity-timeline-content .attachment {
  display: block;
}
