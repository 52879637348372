.export-wizard {
  padding: 0;
}
.export-wizard .required-field {
  color: red;
}
.export-wizard .section-export {
  padding-bottom: 20px;
}
.export-wizard .section-export h3 {
  font-weight: bold;
}
.export-wizard .export-wizard-steps {
  padding: 0 0 20px 0;
}
.export-wizard h2 {
  font-weight: bold;
}
.export-wizard .section-export ul {
  margin: 0;
  padding: 0;
}
.export-wizard .section-export li .ant-radio-checked .ant-radio-inner {
  border-color: #EA4A69;
}
.export-wizard .section-export li .ant-radio-inner:after {
  background-color: #EA4A69;
}
.export-wizard .float-right {
  float: right;
}
.export-wizard .primary-key-field {
  padding-left: 30px;
}
.export-wizard .ant-card {
  border: 1px solid #333;
}
.export-wizard .pause-btn {
  margin-top: 10px;
}

.export-wizard .ant-progress-inner {
  background: #FBDCDF;
}
.export-wizard .ant-progress-bg {
  background: #ED1C24;
}
.export-log-content{
  max-height: 200px;
  overflow: auto;
}