.custom-scrollbar {
    overflow: hidden;
}
.scrollbar-container {
    overflow: scroll;
}
.scrollbar-track-vertical {
    width: 2px !important;
    right: 2px;
    bottom: 2px;
    top: 2px;
    border-radius: 3px;
}
.scrollbar-thumb-vertical {
    cursor: pointer;
    border-radius: inherit;
    background-color: rgba(0, 0, 0, 0.2);
    transform: translateY(50.9804px);
}

.scrollbar-track-horizontal {
    height: 6px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    border-radius: 3px;
}
.scrollbar-thumb-horizontal {
    cursor: pointer;
    border-radius: inherit;
    background-color: rgba(0, 0, 0, 0.2);
    width: 0;
    opacity: 0;
}
