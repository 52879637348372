.ant-table-thead{
  .ant-table-cell{
    font-weight: bold;
  }
}
.ant-drawer-body{
  .ant-table-thead{
    .ant-table-cell{
      text-align: center;
    }
  }
}



.ant-table-tbody>tr>td{
  padding: 16px 5px 16px 12px;
  overflow-wrap: break-word;
}