.notification-drop-down {
  width: 400px;
}
.notification-item {
  border-top: 1px solid #F0F0F0;
  padding: 10px 0 0 0;
}
.notification-item-content .subject{
  display: block;
  font-weight: bold;
}
.notification-item-content .message {
  display: block;
}

.notification-item-top {
  display: flex;
  flex-direction: row;
}
.notification-item-icon {
  padding: 0 15px 0 0;
  align-self: center;
}
.notification-item-action {
  padding: 10px 0 0 0;
  display: flex;
  flex-direction: row;
  height: 40px;
}
.notification-item-action .item {
  align-items: flex-start;
}
.notification-item-action .btn-group {
  flex: 1;
  display: flex;
}
.notification-item-action .btn-group .btn-mark-as-read {
  flex: 1;
  text-align: right;
}
.notification-item-action .btn-group .btn-delete-notification {
  text-align: right;
}
.notification-footer a {
  display: block;
  text-align: center;
}
.notification-item {
  background: #FFF3CD;
}
.notification-item-read {
  background: #FFF;
}
.notification-item-hover {
  background: #F5F5F5;
}
.notification-drop-down .notification-settings{
  position: absolute;
  right: 20px;
  top: 20px;
}
.notification-filter {
  height: 35px;
  margin-bottom: 10px;
}
.notification-filter-search-box {
  width: 250px;
  float: left;
}
.notification-filter-select-box {
  width: 100px;
  float: left;
  margin-left: 10px;
}