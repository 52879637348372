#myAccountForm{
  padding: 40px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0px 2px 2px #00000012;
  box-sizing: border-box;
}
#myAccountForm .ant-row .ant-form-item{
  display: block;
}
.my-img-upload {
  background-color: transparent;
  display: flex;
}
.my-img-upload  .ant-row .ant-form-item{
  border: none;
}
.my-img-upload .ant-form-item-label{

}
.my-img-upload .ant-form-item-control-input-content {
  border: none;
}
.my-img-upload  .ant-upload-select-picture-card{
  border-radius: 50px;
  height: 60px;
  width: 60px;
  margin: 0;
}
.my-img-upload .profile-editing{
  margin-left: 15px;
  margin-top: 0px;
}
.my-img-upload .profile-editing h2{
  margin-bottom: 0;
}
.my-img-upload .profile-editing p{
  margin: 0;
  padding: 0;
}
#myAccountForm .ant-input {
  width: 100%;
  padding: 7px 11px;
}
.my-img-upload .ant-form-item-control-input {
  width: 60px !important;
}
#myAccountForm_mobile{
  margin-right: 15px;
}
#myAccountForm .ant-form-item-control-input-content {
  margin-right: 50px;
}
#myAccountForm .admin-login-submit-btn{
  margin-top: 15px;
}
.submit-loading-button{
  border-radius: 3px;
  box-shadow: none;
  padding: 3px 15px;
  font-size: 13px;
  font-weight: 400;
  background: linear-gradient(to bottom, #F95978 0, #EA4A69 100%);
  border: 1px solid #EA4A69;
  color: #fff;
}
#myAccountForm .ant-form-item-has-success{
  margin-top: 20px !important;
}
#myAccountForm .ant-col-xxl-2{
  max-width: 100% !important;
}

//
#changePasswordForm{
  padding: 40px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0px 2px 2px #00000012;
  box-sizing: border-box;
}
#changePasswordForm .ant-row .ant-form-item{
  display: block;
}
#changePasswordForm .ant-input {
  width: 100%;
  padding: 7px 11px;
}
#changePasswordForm{
  margin-right: 15px;
}
#changePasswordForm .ant-form-item-has-success{
  margin-top: 20px !important;
}
#changePasswordForm .ant-col-xxl-2{
  max-width: 100% !important;
}
#changePasswordForm .ant-form-item-control-input-content {
  margin-right: 50px;
}
#changePasswordForm .admin-login-submit-btn{
  margin-top: 15px;
}
#changePasswordForm .ant-form-item-has-success{
  margin-top: 20px !important;
}
//Settings form

#generalSettingForm{
  padding: 40px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0px 2px 2px #00000012;
  box-sizing: border-box;
}
#generalSettingForm .ant-input {
  width: 100%;
  padding: 7px 11px;
}

#generalSettingForm{
  margin-right: 15px;
}
#generalSettingForm .ant-form-item-control-input-content {
  margin-right: 50px;
}
#generalSettingForm .admin-login-submit-btn{
  margin-top: 15px;

}
#generalSettingForm .submit-btn{

}
#generalSettingForm .ant-btn-primary{
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 3px;
  box-shadow: none;
  padding: 3px 15px;
  font-size: 13px;
  font-weight: 400;
  background: linear-gradient(to bottom, #F95978 0, #EA4A69 100%);
  border: 1px solid #EA4A69;
  color: #fff;
}
#generalSettingForm .ant-form-item-has-success{
  margin-top: 20px !important;
}
#generalSettingForm .ant-col-xxl-2{
  max-width: 100% !important;
}


//secound
#generalSettingFormtwo{
  margin-top: 30px;
  padding: 40px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0px 2px 2px #00000012;
  box-sizing: border-box;
}
#generalSettingFormtwo{
  margin-right: 15px;
}
#generalSettingFormtwo .ant-form-item-control-input-content {
  margin-right: 50px;
}
#generalSettingFormtwo .admin-login-submit-btn{
  margin-top: 15px;
}
#generalSettingFormtwo .ant-btn-primary{
  margin-top: 15px;
  border-radius: 3px;
  box-shadow: none;
  padding: 3px 15px;
  font-size: 13px;
  font-weight: 400;
  background: linear-gradient(to bottom, #F95978 0, #EA4A69 100%);
  border: 1px solid #EA4A69;
  color: #fff;
}
#generalSettingFormtwo .ant-input {
  width: 100%;
  padding: 8px 11px;
}
#generalSettingForm .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
  padding: 5px 11px;
}
.auth-app-wrapper .admin-loginpage {
  width: 400px;
  border: 1px solid #e3e5eb7d;
  border-radius: 5px;
  background: #fff;
  padding: 60px 20px 40px 20px;
  box-shadow: 0px 20px 20px 0px #30416c29;
  opacity: 0.97;
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translate(-10%, -50%);
}



.auth-app-wrapper .logo_login_from1{
  text-align: center;
}
.auth-app-wrapper .admin-loginpage .submit-loading-button{
  width: 100%;
  border-radius: 0px;
  height: 45px;
}
.auth-app-wrapper .admin-loginpage .ant-form-item-control-input-content{
  margin-top: 20px;
}
.auth-app-wrapper .admin-loginpage  #loginForm_email{
  padding: 10px;
}
.auth-app-wrapper .row-admin-login.row-admin-extratext {
  text-align: right;
}
.green-btn {
  margin-right: 10px;
}

.ant-form-item-control-input-content {
  border: none !important;
}
.ant-drawer-wrapper-body{
  background-color: #f5f5f5;
}

.ant-input[disabled], .ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
  background-color: #E3E8EE;
}

.ant-input-focused, .ant-input:focus{
  border-color: #ff4d4f;
  box-shadow: 0 0 0 2px #ff4d4f1a;
}



