
.header-quick-menu{
	background-color: transparent;
	padding: 18px 45px 0px 200px;
    margin: 0 auto;
}
.header-quick-menu .ant-menu-horizontal {
	background-color: transparent;
	border-bottom: none;
}
.header-quick-menu .ant-menu-item{
	border-bottom: none;
	font-size:20px;
	font-weight:700;
	padding-left:0px;
	padding-right:0px;
}
.header-quick-menu .ant-menu-item a{
	padding-left:0px;
	padding-right:80px;
}
.header-quick-menu .ant-menu-item a{
 color: #000;
}
.header-quick-menu .ant-menu-item:hover{
	border-bottom: none;
}
.header-quick-menu .ant-menu-item a:hover{
	color: #1dbf74;
}
.header-quick-menu .ant-menu-item a.active{
	color: #1dbf74;
}
.header-quick-menu .group-logo{
	//float: right;
	//padding-right:0px;
	//margin-top: -14px;
}
.company-logo{
	position: relative;
	left: -20px;
}
.company-logo img{
	width: auto;
	height: 30px;
	position: absolute;
	top: 11px;
	left: 10px;
}
.anticon-plus-circle{
	font-size: 20px;
}