.dashboard-grid-style {
    width: 100%;
    text-align: center;
};

.card-wrapper.custom-card{
    background: #fff;
    text-align: center;
    padding: 50px;
}

.chart-wrapper.custom-chart-wrapper{
    background: #fff;
    padding: 50px;
}