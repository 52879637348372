.ant-btn-primary{
  border-color: #d1d1d1;
}
.record-detail-button-bar{
  .ant-btn-primary{
    border-color: #dce0ec;
    background: #fff;
  }
  .ant-btn-primary:focus, .ant-btn-primary:hover, .record-detail-info .actions button:hover {
    color: #ffffff;
    background: #565c6f;
  }
}