#header{
  background-color: #f5f5f5;
  display: flex;
  z-index: 50;
  box-shadow: 0 8px 10px #0b132b1c;
}
.header-tabs {
  position: relative;
  margin-left: 150px;
}
.header-tabs .header-tab-menu{
  position: absolute;
  background-color: transparent;
  display: flex;
  bottom: -7px;
}
#header .header-tabs .header-tab-menu .ant-menu-item {
  position: relative;
  float: left;
}
#header .header-tabs .header-tab-menu .ant-menu-item.ant-menu-item-selected a{
  background: #f5f5f5!important;
}
#header .header-tabs .header-tab-menu .ant-menu-item a {
  border-radius: 8px 8px 0 0;
  margin: 0;
  padding: 8px 15px;
  border: 1px solid #d9d9d9;;
  color: #000;
  text-align: center;
}
#header .header-tabs .header-tab-menu .ant-menu-item a.active {
  background: #FFF;
}
.ant-menu{
  background-color: #0b132b;
  //display: flex;
}
.header-quick-links{
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.quick-link-item{
  padding-left: 15px;
  padding-right: 15px;
  line-height: 0px;
  padding-top: 12px;
}
.ant-layout-header .app-header{
  background-color: #0b132b  !important;
}
//.ant-menu-item:active, .ant-menu-submenu-title:active {
//  background-color: green !important;
//}
.ant-menu-item a {
  color: #fbfcff;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}

span.anticon.anticon-desktop{
  color: #fbfcff;
}
span.anticon.anticon-pie-chart{
  color: #fbfcff;
}
.ant-layout-header {
  height: 50px;
  padding: 0 20px;
}
li.active.ant-menu-item-only-child {
  width: 14%;
}
.active {
  //display: block;
  //vertical-align: middle;
  //-webkit-transform: perspective(1px) translateZ(0);
  //transform: perspective(1px) translateZ(0);
  //box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  //position: relative;
  //-webkit-transition-property: color;
  //transition-property: color;
  //-webkit-transition-duration: 0.3s;
  //transition-duration: 0.3s;
}
//.active::before{
//  content: "";
//  position: absolute;
//  z-index: -1;
//  top: 0;
//  left: 0;
//  right: 0;
//  bottom: 0;
//  background: #997b99;
//  -webkit-transform: scaleX(0);
//  transform: scaleX(0);
//  -webkit-transform-origin: 0 50%;
//  transform-origin: 0 50%;
//  -webkit-transition-property: transform;
//  transition-property: transform;
//  -webkit-transition-duration: 0.3s;
//  transition-duration: 0.3s;
//  -webkit-transition-timing-function: ease-out;
//  transition-timing-function: ease-out;
//  overflow: hidden !important;
//}
//
//.active:hover, .active:focus, .active {
//  color: white;
//}
//.active:hover:before, .ant-menu-item:focus:before, .active:active:before {
//  -webkit-transform: scaleX(1);
//  transform: scaleX(1);
//}

//.ant-menu-item-selected {
// background-color: transparent !important;
//  transform: scaleX(1) !important;
//}
//
//.ant-menu-item{
//  position: relative;
//  display: block;
//}
//.ant-menu-item-selected a:before {
//  content: "";
//  display: block;
//  z-index: -1;
//  position: absolute;
//  left: -100%;
//  top: 0;
//  width: 100%;
//  height: 100%;
//  border-right: 5px solid #ff586b;
//  background: hsla(0,0%,78.4%,.2);
//  -webkit-transition: left .35s ease;
//  transition: left .35s ease;
//}
//span a.active:before {
//  left: 0;
//  transition: left .35s ease;
//}

//Footer Section start
#footer{
  position: fixed;
  margin-top: 30px;
  padding: 5px 0px 5px 0px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
}
#footer .copyright-text{
  text-align: center;
}
.collapse-action{
  color: red;
  background-color: #ffffff;
}
ul.setting-navigation-menu {
  background-color: #fafafa !important;
  padding: 0;
  margin: 0;
  width: 220px !important;
  z-index: 999 !important;
  border-right: 1px solid #d1d1d1;
}
ul.to-do-list-navigation-menu {
  background-color: #fafafa !important;
  padding: 0;
  margin: 0;
  width: 220px !important;
  z-index: 999 !important;
  border-right: 1px solid #d1d1d1;
}

ul.setting-navigation-menu li{

}
ul.setting-navigation-menu li{
  list-style: none;
}
ul.setting-navigation-menu li a{
  color: #000000;
  font-weight: 700;
  font-size: 14px;
  padding-left: 15px;
}
ul.to-do-list-navigation-menu li a{
  color: #000000;
  font-weight: 700;
  font-size: 14px;
}

ul.setting-navigation-menu li .sub-nav{
  list-style: none;
  padding: 0;
  margin: 0;
}
ul.to-do-list-navigation-menu li .sub-nav{
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.setting-navigation-menu li .sub-nav a{
  color: #000000 !important;
  font-weight: 400;
  font-size: 14px;
  transition: 0.5s;
  display: block;
  padding: 5px 15px;
}
ul.setting-navigation-menu li .sub-nav a:hover{
  background: #E3E8EE;
}
ul.setting-navigation-menu li .sub-nav a.active{
  background: #E3E8EE;
}


ul.to-do-list-navigation-menu li .sub-nav a{
  color: #000000 !important;
  font-weight: 400;
  font-size: 14px;
}

.setting-navigation-menu .nav-link{
  padding-top: 8px;
  padding-bottom: 5px;
  padding-left: 0px;
}

.to-do-list-navigation-menu .nav-link{
  padding-top: 8px;
  padding-bottom: 5px;
  padding-left: 15px;
}

//.setting-navigation-menu .sub-nav .sub-nav-link{
//  padding-top: 3px;
//  padding-bottom: 3px;
//}
.to-do-list-navigation-menu .sub-nav .sub-nav-link{
  padding-top: 3px;
  padding-bottom: 3px;
  list-style: none;
}
.ant-layout-sider {
  background: #FFF !important;
}
.main-content {
  padding: 70px 20px 0px 110px;
}
.auth-layout .main-content{
  padding: 0;
}
.auth-layout .content-wrapper {
  min-height: calc(100vh);
  display: flex;
  justify-content: center;
  align-items: center;
}
.listing-page-container .ant-btn-primary{
  background: linear-gradient(to bottom,#F95978 0,#EA4A69 100%);
  border: 1px solid #EA4A69;
  border-radius: 3px;
}
.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item .anticon+span, .ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title .anticon+span, .ant-menu-inline-collapsed>.ant-menu-item .anticon+span, .ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title .anticon+span {
  display: inline-block;
  max-width: 100px;
  opacity: 1;
}

.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-item, .ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-vertical .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title {
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 0px;
  overflow: hidden;
  line-height: 40px;
  text-overflow: ellipsis;
}
.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item .anticon, .ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title .anticon, .ant-menu-inline-collapsed>.ant-menu-item .anticon, .ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title .anticon {
  margin: 0;
  font-size: 16px;
  line-height: 40px;
  display: block;
}
.ant-menu-inline-collapsed>.ant-menu-item, .ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item, .ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title, .ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title {
  left: 0;
  padding: 0px 0px;
  text-overflow: clip;
  height: 75px;
  text-align: center !important;
}

.ant-menu-inline .ant-menu-item:not(:last-child), .ant-menu-vertical-left .ant-menu-item:not(:last-child), .ant-menu-vertical-right .ant-menu-item:not(:last-child), .ant-menu-vertical .ant-menu-item:not(:last-child) {
  margin-bottom: 0px;
  text-align: left;
}
.anticon .anticon-pie-chart{
  display: block;
  text-align: center;
}
.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left{
  border-right: none;
}
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon, .ant-menu-inline-collapsed > .ant-menu-item .anticon, .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
  font-size: 20px;
  line-height: 20px;
  color: #c0c0cb;
  font-weight: 800;
}
.ant-menu-item a {
  color: #fbfcff;
  font-size: 13px !important;
}
.ant-menu-item a.active{
  color: #fff;
}

.app-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.app-left-nav {
  position: fixed;
  top: 49px;
  left: 0;
  background: #0b132b !important;
  height: calc(100vh - 49px);
  z-index: 5;
}
.ant-layout .ant-layout-has-sider aside.app-left-nav.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed{
  flex: 0 0 90px!important;
  max-width: 90px!important;
  min-width: 90px!important;
  width: 90px!important;
}
aside.app-left-nav.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed ul{
  width: 90px;
}
.app-setting-left-nav, .app-to-do-list-left-nav {
  position: fixed;
  top: 49px;
  left: 92px;
  height: calc(100vh - 49px);
  background: #FFF !important;
  z-index: 5;
}
.content-wrapper {
  min-height: calc(100vh);
}

.setting-layout .content-wrapper, .to-do-list-layout .content-wrapper {
  margin-left: 280px;
  position: relative;
  top: 50px;
}

.to-do-list-layout .main-content{
  padding: 0 0 0 15px;
}

.to-do-list-layout .main-content .listing-page-container{
  padding-right: 15px;
}
.content-wrapper .main-content{
  padding-bottom: 70px!important;
}
.setting-layout .content-wrapper .main-content, .setting-layout .content-wrapper #generalSettingForm {
  margin-right: 0;
  padding: 0px 15px;
}

.left-navigation-scrollbar {
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
  height: auto;
  min-height: calc(100vh - 80px);
  border-right: 1px solid #d1d1d1;
}
.auth-app-wrapper .content-wrapper {
  background: url("../../assets/images/login.jpg") no-repeat;
  background-position: left center;
  background-size: contain;
  background-color: #fff;
}

.ant-menu-item a {
  color: #bfbfbf;
  font-size: 14px;
}
.ant-menu-item a:hover{
  color: #ffffff;
}
.ant-menu-item:hover .anticon svg {
  color: red;
}
.ant-menu-item-selected .anticon svg {
  color: red !important;
}

.ant-menu-inline .ant-menu-item:not(:last-child), .ant-menu-vertical-left .ant-menu-item:not(:last-child), .ant-menu-vertical-right .ant-menu-item:not(:last-child), .ant-menu-vertical .ant-menu-item:not(:last-child) {
  padding-top: 11px;
}
//.setting-navigation-menu .sub-nav .sub-nav-link:hover{
//  padding-top: 3px;
//  padding-bottom: 3px;
//  background-color: #E3E8EE;
//  transition: all 1s;
//}
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon + span, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span, .ant-menu-inline-collapsed > .ant-menu-item .anticon + span, .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span {
  display: inline-block;
  max-width: 100px;
  opacity: 1;
  color: #bfbfbf !important;
  font-size: 14px !important;
}
.more-sub-menu-item {
  line-height: 15px !important;
  padding-top: 0 !important;
}
.more-sub-menu-item:hover{
  background-color: #E3E8EE !important;
  color: #000 !important;
}

.more-sub-menu-item, .anticon{
  padding-left: 10px;
}
.more-sub-menu-item a{
  color: #000 !important;
  font-size: 15px !important;
  line-height: 38px !important;
  padding: 0px !important;
}
.grid-row-view-action{
  color: #0b132b;
  cursor: pointer;
  font-weight: bold;
}
.view-page-content-right.activity-timeline{
  background: #f9fbff;
  padding: 10px 15px;
  box-shadow: -1px 0px 10px #c8cbd4;
  min-height: 100%;
}
.view-page-content .view-page-content-left{
  padding: 24px 24px 0 24px;
}

.view-page-drawer-challan .ant-drawer-body,
.view-page-drawer-sales-order .ant-drawer-body,
.view-page-drawer-collection .ant-drawer-body,
.view-page-drawer-transfer-order .ant-drawer-body,
.view-page-drawer-transfer-order-request .ant-drawer-body
{
  padding:0;
}


@media screen and (max-width: 1921px) and (min-width: 1024px) {
  .ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-item, .ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-vertical .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title {
    line-height: 20px!important;
  }
  .ant-menu-inline-collapsed > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title{
    height: 58px;
  }
  .ant-menu-inline .ant-menu-item:not(:last-child), .ant-menu-vertical-left .ant-menu-item:not(:last-child), .ant-menu-vertical-right .ant-menu-item:not(:last-child), .ant-menu-vertical .ant-menu-item{
    padding-top: 11px;
  }
}